/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Menu, MenuButton, MenuWrapBox, Button, MenuButtonClose, Text, Clearfix, Image, ColumnHeaderWrap, ColumnContent, ContactForm, Fullmap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1c6uie2 --left bg--center" style={{"paddingTop":108,"marginBottom":0,"paddingBottom":43,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"vb6rsx31h7i"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4 title-box--center ff--3 fs--48 w--500 lh--2" style={{"marginTop":27.693748474121097,"paddingTop":0,"marginBottom":1,"paddingBottom":0}} content={"MIRA studio"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--18" style={{"marginTop":0,"paddingTop":0}} content={"masáže &amp; kosmetika"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--22 ls--12" style={{"paddingLeft":17,"paddingBottom":30.46875}} content={"<span style=\"font-style: normal;\">SIMONA BOHÁČOVÁ</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--12 pt--12" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} menu={true}>
          
          <Menu className="--full" fullscreen={true}>
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--center">
              
              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/"} target={""} content={"<span style=\"font-weight: 700; color: var(--black);\">HLAVNÍ STRÁNKA</span>"}>
              </Button>

              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"https://mira-studio.cz/masaze"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">MASÁŽE</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} url={"https://mira-studio.cz/kosmetika"} href={"https://mira-studio.cz/kosmetika"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">KOSMETIKA</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/darkovepukazy"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">DÁRKOVÉ POUKAZY</span>"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="--invert" style={{"marginTop":0,"paddingTop":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(235,214,139,1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" style={{"marginTop":0,"marginBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":10.92498779296875}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":342}} content={"<a href=\"https://www.facebook.com/mirastudiobrno\" style=\"color: var(--black); font-weight: bold;\">Sledujte MIRA studio na&nbsp; FACEBOOKU</a>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Přečtěte si něco zajímavého nejen ze světa MIRA studia, ale z oblasti masáží a kosmetiky celkově.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":6}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":280}} content={"<span style=\"color: var(--black); font-weight: bold;\"><a href=\"https://www.instagram.com/mirastudio_brno/?hl=cs\">Každý den v kontaktu na&nbsp; INSTAGRAMU</a></span>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Pokud rádi sledujete stories a zapojujete se do obsahu, tohle je místo pro Vás! Navíc zde vždy zveřejňuji last-minute uvolněné termíny.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":14.350006103515623}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":280}} content={"<span style=\"color: var(--black); font-weight: bold;\"><a href=\"https://www.google.com/search?q=mira+studio&amp;client=opera&amp;hs=i0t&amp;sxsrf=APwXEdcv9CkCNmTCExb5JMuz5d5UOoXzoQ%3A1687005195104&amp;ei=C6iNZKT0BdCV9u8PntasiAM&amp;ved=0ahUKEwjkpY2LqMr_AhXQiv0HHR4rCzEQ4dUDCA4&amp;uact=5&amp;oq=mira+studio&amp;gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzINCC4QrwEQxwEQigUQJzIHCCMQigUQJzIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIICAAQgAQQywE6CwgAEIAEELEDEIMBOgcIABCKBRBDOgsILhCKBRCxAxCDAToHCC4QigUQQzoLCAAQigUQsQMQgwE6CwguEIAEELEDEIMBOhEILhCABBCxAxCDARDHARCvAToICAAQgAQQsQM6BQguEIAEOgsILhCABBDHARCvAToLCC4QgwEQsQMQgAQ6BQgAEIAEOgsILhCvARDHARCABEoECEEYAFAAWLoYYLobaABwAXgBgAHbAYgBzQySAQUxLjkuMZgBAKABAcABAQ&amp;sclient=gws-wiz-serp#lrd=0x47733399a837f975:0xe8cf48225afd9e4b,1,,,,\">Přečtěte si recenze na GOOGLU</a></span>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Podívejte se, jaké hodnocení mi napsali zákazníci a zákaznice a jak byli s mojí službou spokojeni.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <Clearfix >
          </Clearfix>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0,"backgroundColor":"var(--color-main-bg-2)"}} name={"uow7u16f1t"}>
          
          <ColumnWrap className="column__flex el--2 flex--stretch" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} columns={"2"}>
          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":9,"marginBottom":0,"paddingBottom":10.693756103515623,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"za2ykkebwz"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box ff--3" content={"<span style=\"text-decoration-line: underline; font-weight: bold;\">Aktuálně:</span>"}>
              </Title>

              <Text className="text-box ff--3" content={"<span style=\"font-weight: 700;\">Dovolená:<br></span>24.12. - 1.1.2025<br><span style=\"font-weight: bold;\"><br>DÁRKOVÉ POUKAZY jako vánoční dárek</span><br>= není lepší dárek než čas pro sebe, odpočinek a kvalitní péče<br><br><span style=\"font-weight: bold;\">KOSMETICKÉ BALÍČKY NA MÍRU jako vánoční dárek<br></span>= pomohu Vám sestavit ten nejlepší kosmetický balíček dle přání a potřeb pleti obdarované/ho<br><br>Voucher na kosmetiku nebo na masáž můžete darovat k jakékoliv příležitosti a zakoupit jej můžete na místě ve studiu nebo elektronicky po předchozí domluvě.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":429}} src={"https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=2000x_.jpg 2000w"} position={{"x":"-35.29783037475345%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":2,"paddingBottom":5.462493896484375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"i20zvrchxjl"} layout={"l16"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box ff--3 fs--14 w--300 mt--06" content={"<span style=\"font-style: italic;\">Bez úsměvu to by to nešlo!</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20" content={"<span style=\"font-weight: bold;\">O MIRA studiu</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--14" content={"<span style=\"font-style: italic; text-decoration-line: underline;\">Je to sen, můj sen a já Vás v něm velmi ráda přivítám.</span><br><br>Práce masérky a kosmetičky mě neskutečně baví a stále se učím nové informace, postupy a techniky, abych Vám mohla poskytovat co nejkvalitnější služby!<br><br>Zaměřuji se na <a href=\"/masaze\" style=\"color: rgb(0, 0, 0); font-weight: bold; font-style: italic;\">masáže</a>, které jsou nejen příjemné a relaxační, ale jdou do hloubky a skutečně pomáhají - od bolesti, od stresu, od nespavosti,...<br><br><a href=\"/kosmeticka-pece\" style=\"color: rgb(0, 0, 0); font-weight: bold; font-style: italic;\">Kosmetické ošetření</a> je skutečně zážitek. Používám kvalitní a luxusní českou kosmetiku, moderní přístroje a účinné postupy, díky nimž dáme společně Vaši pleť do té nejlepší možné kondice.<br><br>Provozovna je menší, ale moderní a útulná, jsem si jistá, že se u mě budete cítit skutečně skvěle!<br><br><span style=\"font-style: italic; text-decoration-line: underline;\">Přijďte vyzkoušet moje služby - moc se na Vás těším!</span><br><span style=\"font-style: italic;\">Simona Boháčová</span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"paddingBottom":6.98126220703125,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"kqao2p118sb"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--20" style={{"marginBottom":0,"paddingBottom":3}} content={"<span style=\"font-weight: bold;\">Poprvé na masáž nebo kosmetiku?</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--14 ls--01" style={{"marginTop":20.381256103515625,"paddingTop":0}} content={"<span style=\"text-decoration-line: underline; font-weight: bold;\">Upozornění:&nbsp;</span><br>Před masážemi nejíst alespoň 2 hodiny.<br><br><span style=\"font-weight: 700; text-decoration-line: underline;\">Masáž NIKDY neprovádím, jestliže klient má následující zdravotní obtíže:<br></span>zhoubné nádory a zhoubná mateřská znaménka, horečku, hnisavá kožní onemocnění, poruchy krvácivosti, záněty ledvin, tuberkulózu, kožní hnisavá nebo nebo infekční onemocnění, záněty žil, trombózu, křečové žíly, akutní záněty a akutní úrazy, problémy se srdcem<br><span style=\"font-weight: bold;\">Dále nemasíruji ženy těhotné a v šestinedělí.</span><br>Na masáž není vhodné chodit, jestliže máte právě menstruaci.<br><br><span style=\"font-style: italic;\">Jestliže máte pochybnosti, zda je pro Vás masáž vhodná, neváhejte mě kontaktovat. </span><br><span style=\"font-style: italic;\">&nbsp;Poradím Vám ve Vašem nejlepším zájmu - kvalitní péče o zákazníky je moje priorita.</span><br><br>Platit můžete <span style=\"font-weight: bold;\">bezkontaktně</span>, <span style=\"font-weight: bold;\">hotovostí</span> nebo kartou <span style=\"font-weight: bold;\">Benefit Plus</span> (pouze za masáže).<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"marginTop":0,"paddingTop":15,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"yr4cvon2s09"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/3109ff55914f4860a4b32084e0b038a1_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/3109ff55914f4860a4b32084e0b038a1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/3109ff55914f4860a4b32084e0b038a1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/3109ff55914f4860a4b32084e0b038a1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/3109ff55914f4860a4b32084e0b038a1_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/151370b0975d4d5486b20200d18e3355_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/151370b0975d4d5486b20200d18e3355_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/151370b0975d4d5486b20200d18e3355_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/151370b0975d4d5486b20200d18e3355_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/151370b0975d4d5486b20200d18e3355_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/2e080d0aef764618b9616a7a4e4069f9_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/2e080d0aef764618b9616a7a4e4069f9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/2e080d0aef764618b9616a7a4e4069f9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/2e080d0aef764618b9616a7a4e4069f9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/2e080d0aef764618b9616a7a4e4069f9_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":20,"paddingBottom":13.474990844726562,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"objednavkovyformular"} layout={"l5"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--43" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Jak se objednat?</span>"}>
              </Title>

              <Title className="title-box ff--3" content={"Kontaktujte mě"}>
              </Title>

              <Text className="text-box ff--3 fs--14" style={{"maxWidth":470}} content={"<span style=\"font-style: italic;\">Jestliže Vám telefon ihned nezvednu, znamená to, že zrovna pracuji - masíruji nebo provádím kosmetické ošetření a ozvu se Vám tedy zpět, co nejdříve to bude možné. Preferuji SMS zprávu. Na e-maily odpovídám nejpozději do 3 dnů. Děkuji za pochopení.</span>"}>
              </Text>

              <ColumnHeaderWrap className="flex" style={{"paddingBottom":2}}>
                
                <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} svgColor={"rgba(235,214,139,1)"} RootClassName={"column__icon"}>
                </Image>

                <ColumnContent >
                  
                  <Text className="text-box ff--3 fs--14" style={{"maxWidth":321,"paddingLeft":0,"paddingRight":63}} content={"<span style=\"font-weight: bold;\">simca.bohacova@gmail.com</span>"}>
                  </Text>

                </ColumnContent>

              </ColumnHeaderWrap>

              <ColumnHeaderWrap className="flex" style={{"paddingBottom":2}}>
                
                <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/965c3e40d27d46d385ed085e1b11d2fa.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} svgColor={"rgba(235,214,139,1)"} RootClassName={"column__icon"}>
                </Image>

                <ColumnContent >
                  
                  <Text className="text-box ff--3 fs--14" style={{"maxWidth":321,"paddingLeft":0,"paddingRight":63}} content={"<span style=\"font-weight: bold;\">+420 736 150 609</span>"}>
                  </Text>

                </ColumnContent>

              </ColumnHeaderWrap>

              <ColumnHeaderWrap className="flex" style={{"paddingBottom":2}}>
                
                <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/o/g/Ikonky/Adresa/adresa-7.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} svgColor={"rgba(235,214,139,1)"} RootClassName={"column__icon"}>
                </Image>

                <ColumnContent >
                  
                  <Text className="text-box ff--3 fs--14" style={{"maxWidth":321,"paddingLeft":0,"paddingRight":63}} content={"<span style=\"font-weight: 700;\">Lidická 38/1874, Brno 602 00</span><br>"}>
                  </Text>

                </ColumnContent>

              </ColumnHeaderWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape5 --style3 --shadow2 ff--3" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"KONTAKTNÍ ÚDAJE A ADRESA:"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--invert" style={{"paddingTop":34,"marginBottom":0,"paddingBottom":21.681243896484375,"backgroundColor":"var(--color-main-bg-2)"}} name={"97kd8e0pha"}>
          
          <ColumnWrap className="column__flex el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":240,"marginBottom":0,"paddingBottom":4.5}} src={"https://cdn.swbpg.com/t/2871/a90f39d686814eefbc682c74343ef207_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/2871/a90f39d686814eefbc682c74343ef207_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--3 fs--24 title-box--invert" content={"MIRA studio&nbsp;"}>
              </Title>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--10" style={{"maxWidth":400}} content={"IČO: 03616568<br><br>kontaktní osoba: <br><span style=\"font-style: italic;\">Simona Boháčová</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--left ff--3 fs--16 subtitle-box--invert" content={"Kontakt:"}>
              </Subtitle>

              <Text className="text-box text-box--left ff--3 fs--18 w--500 text-box--invert mt--02" content={"+420 736 150 609"}>
              </Text>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--0 pt--0" content={"facebook: @mirastudiobrno<br>"}>
              </Text>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.facebook.com/mirastudiobrno/"} content={"FACEBOOK"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 ff--3 fs--16 subtitle-box--invert" content={"Provozovna:"}>
              </Subtitle>

              <Text className="text-box ff--3 fs--18 w--500 text-box--invert mt--02" content={"Lidická 38/1874\n<br>Brno 602 00"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--13 w--300 text-box--invert mt--0 pt--0" content={"Od Hlavního nádraží tramvají č.1, směr Řečkovice, zastávka Antonínská \nParkování ZDARMA ve dvoře, parkovací čip Vám zapůjčím.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"MIRA STUDIO na Google mapách!"}>
              </Title>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.google.cz/maps/place/MIRA+studio+-+masáže+a+relaxace/@49.2113373,16.5891307,16.22z/data=!4m5!3m4!1s0x47733399a837f975:0xe8cf48225afd9e4b!8m2!3d49.2113978!4d16.5920673"} content={"ZDE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Fullmap name={"enqpd7znz0j"}>
        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}